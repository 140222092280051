export function useKeyEsc(callback: () => void): void {
  const handleKeydown = (event: KeyboardEvent) => {
    const dialogOpen = document.querySelector('[data-headlessui-state="open"]');
    const modalOpen = document.querySelector('[data-modal-open="true"]');

    if (event.key === "Escape" && !dialogOpen && !modalOpen) {
      callback();
    }
  };

  onMounted(() => {
    window.addEventListener("keydown", handleKeydown);
  });

  onUnmounted(() => {
    window.removeEventListener("keydown", handleKeydown);
  });
}
